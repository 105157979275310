<script>
import { BCard } from 'bootstrap-vue'
import DxDateBox from 'devextreme-vue/date-box'
import moment from 'moment'
import DateRangeSelect, { forecastDateRanges } from '@/components/selects/DateRangeSelect.vue'
import ShopSelect from '@/components/selects/ShopSelect.vue'
import ForecastAlgorithmSelect from '@/components/selects/ForecastAlgorithmSelect.vue'

export default {
  name: 'Filters',
  components: {
    ForecastAlgorithmSelect,
    ShopSelect,
    DateRangeSelect,
    BCard,
    DxDateBox,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forecastDateRanges,
      dateRange: 'lastMonth',
      date: moment().subtract(1, 'day').toDate(),
      shopId: null,
      algorithm: 'manual',
    }
  },
  methods: {
    onDateRangeChange(e) {
      this.dateRange = e
      this.onFilterChange()
    },
    onFilterChange() {
      this.$emit('changed', {
        dateRange: this.dateRange,
        date: this.date.toISOString().split('T')[0],
        shopId: this.shopId,
        algorithm: this.algorithm,
      })
    },
    onShopChange(e) {
      this.shopId = e
      this.onFilterChange()
    },
  },
}
</script>

<template>
  <b-card no-body>
    <div class="filter-container tw-flex tw-flex-row tw-gap-3">
      <ShopSelect
        :value="shopId"
        :read-only="readOnly"
        @value-changed="onShopChange"
      />
      <DateRangeSelect
        :value="dateRange"
        :ranges="forecastDateRanges"
        :read-only="readOnly"
        @value-changed="onDateRangeChange"
      />
      <ForecastAlgorithmSelect
        :value="algorithm"
        :read-only="readOnly"
        @value-changed="value => { algorithm = value; onFilterChange() }"
      />
      <div class="tw-relative">
        <label
          v-if="date"
          class="flying-label tw-z-10"
          for="shop-select"
        >Date</label>
        <DxDateBox
          v-model="date"
          :input-attr="{ 'aria-label': 'Date' }"
          type="date"
          :use-mask-behavior="true"
          placeholder="Date"
          display-format="shortdate"
          :read-only="readOnly"
          @value-changed="onFilterChange"
        />
      </div>
    </div>
  </b-card>
</template>

<style scoped lang="scss">
  .filter-container {
    padding: 1rem;
  }
</style>
