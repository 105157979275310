var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forecast-container tw-flex tw-flex-col"},[_c('Filters',{on:{"changed":_vm.onFilterChanged}}),_c('div',{staticClass:"tw-flex tw-flex-1 tw-flex-col md:tw-flex-row tw-gap-3"},[_c('b-card',{staticClass:"tw-m-0 tw-p-3 tw-w-1/2",attrs:{"no-body":""}},[_c('DxDataGrid',{ref:"dataGrid",staticClass:"sxr-grid caption-left regular-text-color",attrs:{"data-source":_vm.avgSales,"show-borders":false,"show-row-lines":true,"show-column-lines":false,"remote-operations":true,"word-wrap-enabled":true,"column-min-width":50,"column-auto-width":true,"allow-column-reordering":false,"on-row-expanding":_vm.onRowExpanding,"width":"100%","height":"70vh"},scopedSlots:_vm._u([{key:"cumulativeTemplate",fn:function(ref){
var cell = ref.data;
return [_c('span',{attrs:{"title":("Raw amount: " + (cell.data.cumulative.amount))}},[_vm._v(_vm._s(cell.data.cumulative.display))])]}},{key:"hourTemplate",fn:function(ref){
var cell = ref.data;
return [_c('span',[_vm._v(_vm._s(cell.data.hour % 12 || 12)+" "+_vm._s(_vm.getAmPM(cell.data.hour)))])]}}])},[_c('DxScrolling',{attrs:{"mode":"virtual","use-native":false}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxColumn',{attrs:{"data-field":"dayOfWeek","caption":"Day"}}),_c('DxColumn',{attrs:{"data-field":"hour","caption":"Hour","alignment":"center","width":"80px","cell-template":"hourTemplate"}}),_c('DxColumn',{attrs:{"data-field":"orders","caption":"Avg. Orders","width":"100px"}}),_c('DxColumn',{attrs:{"data-field":"cumulative.display","caption":"Cumulative","cell-template":"cumulativeTemplate","alignment":"center","width":"110px"}})],1)],1),_c('b-card',{staticClass:"tw-m-0 tw-p-3 tw-w-full",attrs:{"no-body":""}},[_c('DxDataGrid',{ref:"dataGrid",staticClass:"sxr-grid caption-left regular-text-color",attrs:{"data-source":_vm.forecast,"show-borders":false,"show-row-lines":true,"show-column-lines":false,"remote-operations":true,"word-wrap-enabled":true,"column-min-width":50,"column-auto-width":true,"allow-column-reordering":false,"width":"100%","height":"70vh"},scopedSlots:_vm._u([{key:"diffTemplate",fn:function(ref){
var cell = ref.data;
return [_c('span',{class:{
              'tw-text-red-700': _vm.diffInPercentage(cell.data) > 20,
              'tw-text-yellow-400': _vm.diffInPercentage(cell.data) >= 10 && _vm.diffInPercentage(cell.data) <= 20,
              'tw-text-yellow-500': _vm.diffInPercentage(cell.data) < 10 && _vm.diffInPercentage(cell.data) >= 5,
              'tw-text-green-700': _vm.diffInPercentage(cell.data) < 5,
            }},[_vm._v(_vm._s(_vm.diffInPercentage(cell.data).toFixed(2))+"% ")])]}},{key:"diffTemplateUnits",fn:function(ref){
            var cell = ref.data;
return [_c('span',{class:{
              'tw-text-red-700': _vm.diffInPercentageUnits(cell.data) > 20,
              'tw-text-yellow-400': _vm.diffInPercentageUnits(cell.data) >= 10 && _vm.diffInPercentageUnits(cell.data) <= 20,
              'tw-text-yellow-500': _vm.diffInPercentageUnits(cell.data) < 10 && _vm.diffInPercentageUnits(cell.data) >= 5,
              'tw-text-green-700': _vm.diffInPercentageUnits(cell.data) < 5,
            }},[_vm._v(_vm._s(_vm.diffInPercentageUnits(cell.data).toFixed(2))+"% ")])]}},{key:"masterDetailTemplate",fn:function(ref){
            var cell = ref.data;
return [_c('EstimationDataGrid',{attrs:{"filter":_vm.filter,"hour":cell.data.hour}})]}}])},[_c('DxScrolling',{attrs:{"mode":"virtual","use-native":false}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxColumn',{attrs:{"data-field":"date","caption":"Time","alignment":"center","data-type":"datetime","format":"shortTime"}}),_c('DxColumn',{attrs:{"data-field":"total_sales","caption":"Units","width":"100px"}}),_c('DxColumn',{attrs:{"data-field":"estimated_sales","caption":"Est. Units","width":"100px"}}),_c('DxColumn',{attrs:{"data-field":"total_revenue.display","caption":"Revenue"}}),_c('DxColumn',{attrs:{"data-field":"estimated_revenue.display","caption":"Est. Revenue"}}),_c('DxColumn',{attrs:{"data-field":"estimated_revenue.amount","caption":"Diff Rev.","cell-template":"diffTemplate"}}),_c('DxColumn',{attrs:{"data-field":"estimated_revenue.amount","caption":"Diff U.","cell-template":"diffTemplateUnits"}}),_c('DxMasterDetail',{attrs:{"enabled":_vm.isManual,"template":"masterDetailTemplate"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }