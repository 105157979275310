var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DxDataGrid',{ref:"dataGrid",staticClass:"sxr-grid caption-left regular-text-color",attrs:{"data-source":_vm.forecast,"show-borders":false,"show-row-lines":true,"show-column-lines":false,"remote-operations":true,"word-wrap-enabled":true,"column-min-width":50,"column-auto-width":true,"allow-column-reordering":false,"width":"100%","height":"400px"},scopedSlots:_vm._u([{key:"diffTemplate",fn:function(ref){
var cell = ref.data;
return [_c('span',{class:{
        'tw-text-red-700': _vm.diffInPercentage(cell.data) > 20,
        'tw-text-yellow-400': _vm.diffInPercentage(cell.data) >= 10 && _vm.diffInPercentage(cell.data) <= 20,
        'tw-text-yellow-500': _vm.diffInPercentage(cell.data) < 10 && _vm.diffInPercentage(cell.data) >= 5,
        'tw-text-green-700': _vm.diffInPercentage(cell.data) < 5,
      }},[_vm._v(_vm._s(_vm.diffInPercentage(cell.data).toFixed(2))+"% ")])]}},{key:"diffTemplateUnits",fn:function(ref){
      var cell = ref.data;
return [_c('span',{class:{
        'tw-text-red-700': _vm.diffInPercentageUnits(cell.data) > 20,
        'tw-text-yellow-400': _vm.diffInPercentageUnits(cell.data) >= 10 && _vm.diffInPercentageUnits(cell.data) <= 20,
        'tw-text-yellow-500': _vm.diffInPercentageUnits(cell.data) < 10 && _vm.diffInPercentageUnits(cell.data) >= 5,
        'tw-text-green-700': _vm.diffInPercentageUnits(cell.data) < 5,
      }},[_vm._v(_vm._s(_vm.diffInPercentageUnits(cell.data).toFixed(2))+"% ")])]}}])},[_c('DxScrolling',{attrs:{"mode":"virtual","use-native":false}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxColumn',{attrs:{"data-field":"date","caption":"Time","alignment":"center","data-type":"datetime","format":"shortTime"}}),_c('DxColumn',{attrs:{"data-field":"total_sales","caption":"Units","width":"100px"}}),_c('DxColumn',{attrs:{"data-field":"estimated_sales","caption":"Est. Units","width":"100px"}}),_c('DxColumn',{attrs:{"data-field":"total_revenue.display","caption":"Revenue"}}),_c('DxColumn',{attrs:{"data-field":"estimated_revenue.display","caption":"Est. Revenue"}}),_c('DxColumn',{attrs:{"data-field":"estimated_revenue.amount","caption":"Diff Rev.","cell-template":"diffTemplate"}}),_c('DxColumn',{attrs:{"data-field":"estimated_revenue.amount","caption":"Diff U.","cell-template":"diffTemplateUnits"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }