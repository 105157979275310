<script>
import {
  DxColumn, DxDataGrid, DxFilterRow, DxScrolling,
} from 'devextreme-vue/data-grid'

export default {
  name: 'EstimationDataGrid',
  components: {
    DxScrolling, DxFilterRow, DxDataGrid, DxColumn,
  },
  props: {
    hour: {
      type: Number,
      default: 0,
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      forecast: [],
    }
  },
  mounted() {
    this.$store.dispatch('sales/forecast', {
      date_range: this.filter.dateRange,
      date: this.filter.date,
      shop_id: this.filter.shopId,
      hour: this.hour,
    }).then(data => {
      this.forecast = data.forecast
    })
  },
  methods: {
    diffInPercentage(data) {
      if (!data.total_revenue_on_date.amount) {
        return 0
      }
      const diff = data.estimated_revenue.amount - data.total_revenue_on_date.amount
      const percentage = (diff / data.total_revenue_on_date.amount) * 100
      return Math.abs(percentage) % 100
    },
    diffInPercentageUnits(data) {
      if (!data.total_sales_on_date) {
        return 0
      }
      const diff = data.estimated_sales - data.total_sales_on_date
      const percentage = (diff / data.total_sales_on_date) * 100
      return Math.abs(percentage) % 100
    },
  },
}
</script>

<template>
  <DxDataGrid
    ref="dataGrid"
    class="sxr-grid caption-left regular-text-color"
    :data-source="forecast"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :column-min-width="50"
    :column-auto-width="true"
    :allow-column-reordering="false"
    width="100%"
    height="400px"
  >
    <DxScrolling
      mode="virtual"
      :use-native="false"
    />
    <DxFilterRow :visible="true" />
    <DxColumn
      data-field="date"
      caption="Time"
      alignment="center"
      data-type="datetime"
      format="shortTime"
    />
    <DxColumn
      data-field="total_sales"
      caption="Units"
      width="100px"
    />
    <DxColumn
      data-field="estimated_sales"
      caption="Est. Units"
      width="100px"
    />
    <DxColumn
      data-field="total_revenue.display"
      caption="Revenue"
    />
    <DxColumn
      data-field="estimated_revenue.display"
      caption="Est. Revenue"
    />
    <DxColumn
      data-field="estimated_revenue.amount"
      caption="Diff Rev."
      cell-template="diffTemplate"
    />
    <DxColumn
      data-field="estimated_revenue.amount"
      caption="Diff U."
      cell-template="diffTemplateUnits"
    />
    <template #diffTemplate="{ data: cell }">
      <span
        :class="{
          'tw-text-red-700': diffInPercentage(cell.data) > 20,
          'tw-text-yellow-400': diffInPercentage(cell.data) >= 10 && diffInPercentage(cell.data) <= 20,
          'tw-text-yellow-500': diffInPercentage(cell.data) < 10 && diffInPercentage(cell.data) >= 5,
          'tw-text-green-700': diffInPercentage(cell.data) < 5,
        }"
      >{{ diffInPercentage(cell.data).toFixed(2) }}%
      </span>
    </template>

    <template #diffTemplateUnits="{ data: cell }">
      <span
        :class="{
          'tw-text-red-700': diffInPercentageUnits(cell.data) > 20,
          'tw-text-yellow-400': diffInPercentageUnits(cell.data) >= 10 && diffInPercentageUnits(cell.data) <= 20,
          'tw-text-yellow-500': diffInPercentageUnits(cell.data) < 10 && diffInPercentageUnits(cell.data) >= 5,
          'tw-text-green-700': diffInPercentageUnits(cell.data) < 5,
        }"
      >{{ diffInPercentageUnits(cell.data).toFixed(2) }}%
      </span>
    </template>
  </DxDataGrid>
</template>

<style scoped lang="scss">

</style>
