<script>
import { BCard } from 'bootstrap-vue'
import {
  DxColumn, DxDataGrid, DxFilterRow, DxMasterDetail, DxScrolling,
} from 'devextreme-vue/data-grid'
import Filters from '@/views/admin/forecast/components/Filters.vue'
import EstimationDataGrid from '@/views/admin/forecast/components/EstimationDataGrid.vue'

export default {
  name: 'Forecast',
  components: {
    DxMasterDetail,
    DxColumn,
    DxScrolling,
    DxDataGrid,
    BCard,
    Filters,
    DxFilterRow,
    EstimationDataGrid,
  },
  data() {
    return {
      forecast: [],
      avgSales: [],
      filter: {},
      algorithm: 'manual',
    }
  },
  computed: {
    isManual() {
      return this.algorithm === 'manual'
    },
  },
  methods: {
    onFilterChanged(e) {
      this.filter = e
      if (!e.date || !e.shopId || !e.algorithm) {
        return
      }
      this.algorithm = e.algorithm
      this.$store.dispatch('sales/forecast', {
        date_range: e.dateRange,
        date: e.date,
        shop_id: e.shopId,
        algorithm: e.algorithm,
      }).then(data => {
        this.forecast = data.forecast
        this.avgSales = data.average_sales
      })
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1)
    },
    getAmPM(hour) {
      return hour >= 12 ? 'PM' : 'AM'
    },
    diffInPercentage(data) {
      if (!data.total_revenue_on_date.amount) {
        return 0
      }
      const diff = data.estimated_revenue.amount - data.total_revenue_on_date.amount
      const percentage = (diff / data.total_revenue_on_date.amount) * 100
      return Math.abs(percentage) % 100
    },
    diffInPercentageUnits(data) {
      if (!data.total_sales_on_date) {
        return 0
      }
      const diff = data.estimated_sales - data.total_sales_on_date
      const percentage = (diff / data.total_sales_on_date) * 100
      return Math.abs(percentage) % 100
    },
  },
}
</script>

<template>
  <div class="forecast-container tw-flex tw-flex-col">
    <Filters @changed="onFilterChanged" />
    <div class="tw-flex tw-flex-1 tw-flex-col md:tw-flex-row tw-gap-3">
      <b-card
        class="tw-m-0 tw-p-3 tw-w-1/2"
        no-body
      >
        <DxDataGrid
          ref="dataGrid"
          class="sxr-grid caption-left regular-text-color"
          :data-source="avgSales"
          :show-borders="false"
          :show-row-lines="true"
          :show-column-lines="false"
          :remote-operations="true"
          :word-wrap-enabled="true"
          :column-min-width="50"
          :column-auto-width="true"
          :allow-column-reordering="false"
          :on-row-expanding="onRowExpanding"
          width="100%"
          height="70vh"
        >
          <DxScrolling
            mode="virtual"
            :use-native="false"
          />
          <DxFilterRow :visible="true" />
          <DxColumn
            data-field="dayOfWeek"
            caption="Day"
          />
          <DxColumn
            data-field="hour"
            caption="Hour"
            alignment="center"
            width="80px"
            cell-template="hourTemplate"
          />
          <DxColumn
            data-field="orders"
            caption="Avg. Orders"
            width="100px"
          />
          <DxColumn
            data-field="cumulative.display"
            caption="Cumulative"
            cell-template="cumulativeTemplate"
            alignment="center"
            width="110px"
          />
          <template #cumulativeTemplate="{ data: cell }">
            <span
              :title="`Raw amount: ${cell.data.cumulative.amount}`"
            >{{ cell.data.cumulative.display }}</span>
          </template>
          <template #hourTemplate="{ data: cell }">
            <span>{{ cell.data.hour % 12 || 12 }} {{ getAmPM(cell.data.hour) }}</span>
          </template>
        </DxDataGrid>
      </b-card>
      <b-card
        class="tw-m-0 tw-p-3 tw-w-full"
        no-body
      >
        <DxDataGrid
          ref="dataGrid"
          class="sxr-grid caption-left regular-text-color"
          :data-source="forecast"
          :show-borders="false"
          :show-row-lines="true"
          :show-column-lines="false"
          :remote-operations="true"
          :word-wrap-enabled="true"
          :column-min-width="50"
          :column-auto-width="true"
          :allow-column-reordering="false"
          width="100%"
          height="70vh"
        >
          <DxScrolling
            mode="virtual"
            :use-native="false"
          />
          <DxFilterRow :visible="true" />
          <DxColumn
            data-field="date"
            caption="Time"
            alignment="center"
            data-type="datetime"
            format="shortTime"
          />
          <DxColumn
            data-field="total_sales"
            caption="Units"
            width="100px"
          />
          <DxColumn
            data-field="estimated_sales"
            caption="Est. Units"
            width="100px"
          />
          <DxColumn
            data-field="total_revenue.display"
            caption="Revenue"
          />
          <DxColumn
            data-field="estimated_revenue.display"
            caption="Est. Revenue"
          />
          <DxColumn
            data-field="estimated_revenue.amount"
            caption="Diff Rev."
            cell-template="diffTemplate"
          />
          <DxColumn
            data-field="estimated_revenue.amount"
            caption="Diff U."
            cell-template="diffTemplateUnits"
          />

          <DxMasterDetail
            :enabled="isManual"
            template="masterDetailTemplate"
          />

          <template #diffTemplate="{ data: cell }">
            <span
              :class="{
                'tw-text-red-700': diffInPercentage(cell.data) > 20,
                'tw-text-yellow-400': diffInPercentage(cell.data) >= 10 && diffInPercentage(cell.data) <= 20,
                'tw-text-yellow-500': diffInPercentage(cell.data) < 10 && diffInPercentage(cell.data) >= 5,
                'tw-text-green-700': diffInPercentage(cell.data) < 5,
              }"
            >{{ diffInPercentage(cell.data).toFixed(2) }}%
            </span>
          </template>

          <template #diffTemplateUnits="{ data: cell }">
            <span
              :class="{
                'tw-text-red-700': diffInPercentageUnits(cell.data) > 20,
                'tw-text-yellow-400': diffInPercentageUnits(cell.data) >= 10 && diffInPercentageUnits(cell.data) <= 20,
                'tw-text-yellow-500': diffInPercentageUnits(cell.data) < 10 && diffInPercentageUnits(cell.data) >= 5,
                'tw-text-green-700': diffInPercentageUnits(cell.data) < 5,
              }"
            >{{ diffInPercentageUnits(cell.data).toFixed(2) }}%
            </span>
          </template>
          <template #masterDetailTemplate="{ data: cell }">
            <EstimationDataGrid
              :filter="filter"
              :hour="cell.data.hour"
            />
          </template>
        </DxDataGrid>
      </b-card>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .forecast-container {
    height: 85vh;
  }

  @media (max-width: 768px) {
    .forecast-container {
      height: 89vh;
    }
  }
</style>
